import { DEFAULT_MAX_LAYOUT_MODULES } from 'shared_server_client/constants'
import { TierPrice, UsageStats } from './usage_stats'

export const UNLIMITED_VALUE = 1000000
export const DEFAULT_MAX_SECTIONS = 6
export const NO_SECTIONS = 0
export const DEFAULT_MAX_SCHEDULE_DAYS = 2

export enum BillingFrequency {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export enum BillingPlanDetailCode {
  ADVANCED_SOURCE_CONFIG = 'advanced-source-config',
  COST_ADDITIONAL_CONTACTS = 'cost-contact-tier',
  COST_ADDITIONAL_TIER_MESSAGES = 'cost-additional-tier-messages',
  EMAIL_TEMPLATES = 'email-templates',
  FOOTER_BRANDING = 'footer-branding',
  INCLUDED_CONTACTS = 'included-contacts',
  INCLUDED_MESSAGES = 'included-messages',
  INSTAGRAM_INTEGRATION = 'instagram-integration',
  MAX_CONTACTS = 'max-contacts',
  MAX_CONTENT_SOURCES = 'max-content-sources',
  MAX_INTEGRATIONS = 'max-native-integrations',
  MAX_LAYOUT_MODULES = 'max-layout-modules',
  MAX_MESSAGES = 'max-messages',
  MAX_NEWSLETTERS = 'max-newsletters',
  MAX_USERS = 'max-users',
  MAX_BOOST_SOURCES = 'max-boost-sources',
  SCHEDULED_CONTENT = 'scheduled-content',
  SECTIONS = 'sections',
  WEEKLY_REPORT = 'weekly-report',
  RESERVE_CONTENT = 'reserve-content',
  SUSPECT_CLICKS= 'suspect-clicks',
  YOUR_MEMBERSHIP_INTEGRATION = 'your-membership-integration',
  NIMBLE_INTEGRATION = 'nimble-integration',
  FONTEVA_INTEGRATION = 'fonteva-integration',
  IMIS_INTEGRATION = 'imis-integration',
  MAGNET_MAIL_INTEGRATION = 'magnet-mail-integration',
  SEGMENT_CODE = 'segment-code',
  ARTICLE_IMAGE_URL = 'article-image-url',
  REPORTS = 'reports',
  MAX_SPONSORED_POSTS = 'max-sponsored-posts',
  MAX_SCHEDULE_DAYS = 'max-schedule-days',
  DOMAIN_AUTHENTICATION = 'domain-authentication',
  REPORTING_LOOKBACK_MONTHS = 'reporting-lookback-months',
  AB_TESTS = 'ab-test',
  GENERATIVE_AI = 'generative-ai',
  AI_RECOMMENDATIONS = 'ai-recommendations',
  LEAD_TEXT_PHISHING_CHECK = 'lead-text-phishing-check',
  RICH_INTEGRATION = 'rich-integration',
  IMAGE_GALLERY = 'image-gallery',
  IMPEXIUM_INTEGRATION = 'impexium-integration',
  INFORMZ_INTEGRATION = 'informz-integration',
  ZOOM_INTEGRATION = 'zoom-integration',
  MAX_CUSTOM_TEMPLATES = 'max-custom-templates',
  EVENTS = 'events',
  MAX_TOPIC_SOURCES = 'max-topic-source'
}

export const productFeatures: BillingPlanDetailCode[] = [
  BillingPlanDetailCode.ADVANCED_SOURCE_CONFIG,
  BillingPlanDetailCode.EMAIL_TEMPLATES,
  BillingPlanDetailCode.INSTAGRAM_INTEGRATION,
  BillingPlanDetailCode.SCHEDULED_CONTENT,
  BillingPlanDetailCode.WEEKLY_REPORT,
  BillingPlanDetailCode.RESERVE_CONTENT,
  BillingPlanDetailCode.SUSPECT_CLICKS,
  BillingPlanDetailCode.YOUR_MEMBERSHIP_INTEGRATION,
  BillingPlanDetailCode.NIMBLE_INTEGRATION,
  BillingPlanDetailCode.FONTEVA_INTEGRATION,
  BillingPlanDetailCode.IMIS_INTEGRATION,
  BillingPlanDetailCode.MAGNET_MAIL_INTEGRATION,
  BillingPlanDetailCode.SEGMENT_CODE,
  BillingPlanDetailCode.ARTICLE_IMAGE_URL,
  BillingPlanDetailCode.REPORTS,
  BillingPlanDetailCode.REPORTING_LOOKBACK_MONTHS,
  BillingPlanDetailCode.MAX_SPONSORED_POSTS,
  BillingPlanDetailCode.AB_TESTS,
  BillingPlanDetailCode.GENERATIVE_AI,
  BillingPlanDetailCode.AI_RECOMMENDATIONS,
  BillingPlanDetailCode.MAX_SCHEDULE_DAYS,
  BillingPlanDetailCode.LEAD_TEXT_PHISHING_CHECK,
  BillingPlanDetailCode.RICH_INTEGRATION,
  BillingPlanDetailCode.IMPEXIUM_INTEGRATION,
  BillingPlanDetailCode.INFORMZ_INTEGRATION,
  BillingPlanDetailCode.ZOOM_INTEGRATION,
]

// Monthly code for plans, rather than display names
export enum BillingPlanCode {
  ZOHO_STANDARD = 'Standard-1',
  ZOHO_PLUS_2021 = 'PLUS-2021',
  ZOHO_PLUS_2023 = 'PLUS-MONTHLY-2023',
  ZOHO_PLUS_ANNUAL_2023 = 'PLUS-ANNUAL-2023',
  ZOHO_PRO_2021 = 'PRO-202111',
  ZOHO_PRO_2023 = 'PRO-MONTHLY-2023',
  ZOHO_PRO_ANNUAL_2023 = 'PRO-ANNUAL-2023',
  ZOHO_CONTACTS = 'Standard-Contacts',
  APPSUMO_TIER1 = 'rasa_tier1',
  APPSUMO_TIER2 = 'rasa_tier2',
  APPSUMO_TIER3 = 'rasa_tier3',
  APPSUMO_TIER4 = 'rasa_tier4',
  APPSUMO_TIER5 = 'rasa_tier5',
  RASA_FREE_TRIAL = 'free_trial',
  RASA_CONTACT_PRO = 'rasa_contract_pro',
  RASA_ENTERPRISE = 'rasa_enterprise',
}

export enum BillingSystemCode {
  APP_SUMO = 'appsumo',
  RASA = 'rasa',
  ZOHO = 'zoho',
}

export interface BillingPlanDetailTier {
  billing_plan_detail_id: number,
  cost: any,
  id: number,
  maximum: number,
  minimum: number,
}

export interface BillingPlanDetail {
  billing_plan_code: string,
  billing_plan_detail_description: string,
  billing_plan_id: any,
  code: string,
  detail_type: string,
  display: boolean,
  id: number,
  is_absolute: boolean,
  limit_val: any,
  name: string,
  overage_cost: any,
  product_subscription_detail_id?: number,
  tier_minimum?: number,
  tier_maximum?: number,
  tier_cost?: number,
  tiers?: BillingPlanDetailTier[],
  billing_plan_code_type?: string,
  product_subscription_detail_active?: number,
  product_subscription_detail_max?: number
  productDetailMaximum?:number
  productDetailActive?:number
}

export interface BillingPlan {
  annual_cost?: number,
  billing_system_code: string,
  code_monthly: string,
  code_annual: string,
  description: string,
  details: BillingPlanDetail[],
  display: boolean,
  features?: BillingPlanDetailCode[],
  frequency: BillingFrequency,
  id: any,
  is_active: boolean,
  monthly_cost?: number,
  name: string,
  rank?: number,
  url_annual: string,
  url_monthly: string,
}

export interface ProductSubscription {
  account_billing_system_identifier?: string
  account_id?: number,
  account_name?: string,
  account_email?: string,
  billing_plan_id: number,
  billing_system_code: string,
  billing_system_identifier: string,
  code_annual: string,
  code_monthly: string,
  coupon_id: number,
  coupon_code: string,
  coupon_discount_by: string,
  coupon_discount_value: number,
  coupon_duration?: string,
  coupon_expiry_at: string,
  coupon_is_active: boolean,
  coupon_name: string,
  coupon_product_id?: string,
  coupon_source?: string,
  coupon_type: string,
  created: Date,
  end_date: string,
  expiration_date?: Date,
  frequency: BillingFrequency,
  id: number,
  is_cancelled: number,
  is_update_pending: boolean,
  last_billing_end_date?: Date,
  last_billing_period_start_date: Date,
  last_billing_period_end_date: Date,
  pending_update_date: Date,
  start_date: string,
  status: string,
}

export interface CurrentPlan extends BillingPlan {
  coupon_code?: string,
  coupon_discount_by?: string,
  coupon_discount_value?: number,
  expiration_date?: Date,
  includedMessage: number,
  maxMessage: number,
}

export const noCurrentPlan: CurrentPlan = {
  annual_cost: 0,
  billing_system_code: '',
  code_annual: null,
  code_monthly: null,
  description: '',
  details: [],
  display: false,
  frequency: BillingFrequency.MONTHLY,
  id: 0,
  includedMessage: 0,
  is_active: false,
  maxMessage: 0,
  name: '',
  url_annual: null,
  url_monthly: null,
}

export interface CommunityBillingInfo {
  currentPlan: CurrentPlan,
  lastBillingDate: Date,
  nextBillingDate: Date,
  plansToShow: BillingPlan[],
  productSubscription: ProductSubscription,
  usageStats: UsageStats,
}

export const canRemoveFooterBranding = (plan: BillingPlan): boolean => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.FOOTER_BRANDING, 0) > 0
}

export const getMaximumNumberOfIntegrations = (plan: BillingPlan): number => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_INTEGRATIONS, 0)
  return (max >= 0) ? max : UNLIMITED_VALUE
}

export const getMaximumNumberOfDomainAuthentications = (plan: BillingPlan): number => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.DOMAIN_AUTHENTICATION, 0)
  return (max >= 0) ? max : UNLIMITED_VALUE
}

export const getMaximumNumberOfNewsletters = (plan: BillingPlan): number => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_NEWSLETTERS, 0)
  return (max >= 0) ? max : UNLIMITED_VALUE
}

export const getCurrentPlanMaxSources = (plan: BillingPlan): number => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_CONTENT_SOURCES, 10)
  return (max > 0) ? max : UNLIMITED_VALUE
}

export const getCurrentPlanMaxContacts = (plan: BillingPlan) => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_CONTACTS)
  return (max > 0) ? max : UNLIMITED_VALUE
}

export const getCurrentPlanMaxLayoutModules = (plan: BillingPlan) => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_LAYOUT_MODULES)
  return (max > 0) ? max : DEFAULT_MAX_LAYOUT_MODULES
}

export const getCurrentPlanMaxSections = (plan: BillingPlan) => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.SECTIONS)
  return (max > 0) ? max : max === -1 ? DEFAULT_MAX_SECTIONS : NO_SECTIONS
}

export const getCurrentPlanMaxUsers = (plan: BillingPlan) => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_USERS)
}

export const getCurrentPlanMaxScheduleDays = (plan: BillingPlan) => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_SCHEDULE_DAYS, DEFAULT_MAX_SCHEDULE_DAYS)
  return (max > 0) ? max : UNLIMITED_VALUE
}

export const getReportingLookbackMonths = (plan: BillingPlan) => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.REPORTING_LOOKBACK_MONTHS)
}

export const getCurrentPlanMaxBoostSources = (plan: BillingPlan) => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_BOOST_SOURCES)
}

export const getCurrentPlanIncludedContacts = (plan: BillingPlan) => {
  const detail: BillingPlanDetail = getPlanDetail(plan, BillingPlanDetailCode.COST_ADDITIONAL_CONTACTS)
  if ( detail && detail.tier_maximum ) {
    if (detail.is_absolute) {
      return detail.tier_maximum
    } else {
      const includedContatcsDetail: BillingPlanDetail = getPlanDetail(plan, BillingPlanDetailCode.INCLUDED_CONTACTS)
      return detail.tier_maximum + includedContatcsDetail.limit_val
    }
  } else {
    return getPlanDetailLimitValue(plan, BillingPlanDetailCode.INCLUDED_CONTACTS)
  }
}

export const getCurrentPlanIncludedMessages = (plan: BillingPlan) => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.INCLUDED_MESSAGES)
}

export const getCurrentPlanMaxMessages = (plan: BillingPlan) => {
  return getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_MESSAGES)
}

export const getPlanDetail =
  (plan: BillingPlan, code: BillingPlanDetailCode, defaultValue: any = null): BillingPlanDetail => {
  const found: BillingPlanDetail = plan.details.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (d: BillingPlanDetail) => d.billing_plan_code === code)
  if (found) {
    return found
  } else {
    return {
      billing_plan_code: code,
      billing_plan_detail_description: '',
      billing_plan_id: 0,
      code: '',
      detail_type: '',
      display: false,
      id: 0,
      is_absolute: true,
      limit_val: defaultValue,
      name: '',
      overage_cost: null,
    }
  }
}

export const getPlanDetailLimitValue =
(plan: BillingPlan, code: BillingPlanDetailCode, defaultValue: any = null) => {
  return getPlanDetail(plan, code, defaultValue).limit_val
}

export const isFreePlan = (plan: BillingPlan): boolean => {
  return plan && (plan.code_monthly === BillingPlanCode.RASA_FREE_TRIAL.valueOf())
}

export const isEnterprisePlan = (plan: BillingPlan): boolean => {
  return plan &&  plan.code_monthly === BillingPlanCode.RASA_ENTERPRISE.valueOf()
}

export const isRasaPlan = (plan: BillingPlan): boolean => {
  return plan &&  plan.billing_system_code === BillingSystemCode.RASA
}

export const isOverContactLimit = (usageStats: UsageStats, projectedCount: number = 0): boolean => {
  return !usageStats.hasUnlimitedContacts &&
        usageStats.contactCount + projectedCount >= usageStats.currentPlanMaxContacts
}

export const getSelectedPlanOverageCost = (plan: any, messageCount: number) => {
  const includedMessages = getCurrentPlanIncludedMessages(plan)
  const messagesToPayFor = messageCount - includedMessages
  const overageCost = plan.details
              .filter((d: BillingPlanDetail) =>
                d.billing_plan_code === BillingPlanDetailCode.COST_ADDITIONAL_TIER_MESSAGES)
              .map((d: BillingPlanDetail) => d.tiers)
              .filter((t: BillingPlanDetailTier) => t.minimum < messagesToPayFor)
              .reduce((cost, t: BillingPlanDetailTier) => {
                return cost + t.cost * (Math.min(t.maximum, messagesToPayFor) - t.minimum + 1)
              }, 0)
  return Math.round(overageCost)
}

export const getProjectedContactPricing = (plan: BillingPlan, contactCount: number): TierPrice => {
  const detail: BillingPlanDetail = plan.details.find((d: BillingPlanDetail) =>
    d.billing_plan_code === BillingPlanDetailCode.COST_ADDITIONAL_CONTACTS)
  if (detail && detail.tiers) {
    const tier: BillingPlanDetailTier = detail.tiers.find((t: BillingPlanDetailTier) => {
      return t.minimum <= contactCount && t.maximum >= contactCount
    })
    if (tier) {
      return {
        code: detail.code,
        cost: tier.cost,
        count: contactCount,
        id: tier.id,
        maximum: tier.maximum,
      }
    }
  }
  return {
    code: '',
    cost: 0,
    count: 0,
    id: 0,
    maximum: 0,
  }
}

type CodeType = BillingPlanDetailCode | string
export const canAccessPlanFeature = (featureCode: CodeType, plan: BillingPlan): boolean => {
  const detailCode: BillingPlanDetailCode =
    typeof(featureCode) === 'string' ? featureCode as BillingPlanDetailCode : featureCode
  const detail = getPlanDetail(plan, detailCode)
  return detailCode && (detail.limit_val || 0) !== 0
}

export const getCurrentPlanMaxTemplate = (plan: BillingPlan): number => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_CUSTOM_TEMPLATES, 6)
  return (max > 0) ? max : UNLIMITED_VALUE
}

export const getCurrentPlanMaxTopicSource = (plan: BillingPlan) => {
  const max: number = getPlanDetailLimitValue(plan, BillingPlanDetailCode.MAX_TOPIC_SOURCES)
  return (max > 0) ? max : UNLIMITED_VALUE
}